import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AffiliateLayout from "./common/AffiliateLayout";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { dashboarddataApi, getreferralUsers, PayoutReportDataApi } from "backendServices/ApiCalls";
import { Link } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useNavigate } from "react-router-dom";
import RefCustomerTable from "./common/RefCustomerTable";
import PaymentTable from "./common/PaymentTable";
import './setting.css'

import NewTrialsTable from "./common/NewTrialsTable";
import ActiveCustomersTable from "./common/ACtiveCustomersTable";
import CustomerCancelledTable from "./common/CustomerCancelledTable";
import TrialCancelledTable from "./common/TrialCancelledTable";
import AllCustomersTable from "./common/AllCustomersTable";


export default function MemberDashboard() {
  const { t } = useTranslation();
  const [dashboardData, setdDashboardData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [affLoading, setaffLoading] = useState(false);
  const { setAffData, refUsers, setSelectedDate, selectedDate, isAffiliateLoading } =
    useContext(CustomProvider);
  const [affiliateData, setAffiliateData] = useState({});
  const [currentTab, setCurrentTab] = useState("new_trials")
  const navigate = useNavigate();
  const [timeLeftOneMonth, setTimeLeftOneMonth] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timeLeftThreeMonth, setTimeLeftThreeMonth] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timeLeftSixMonth, setTimeLeftSixMonth] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [payoutReportData, setPayoutReportData] = useState([]);

  const now = new Date();
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const formattedLastDay = lastDayOfMonth.toISOString().slice(0, 10) + 'T23:59:59';
  const TableTitle = (currentTab) => {
    switch (currentTab) {
      case 'new_trials':
        return t("pages.title.new-trials") ;
      case 'active_customers':
        return t("pages.title.active-customers");
      case 'trial_cancelled':
        return t("pages.title.cancelled-trials");
      case 'customer_cancelled':
        return t("pages.title.cancelled-customers");
      default:
        return "Default";
    }
  }

  const TableComponent = (currentTab) => {
    switch (currentTab) {
      case 'new_trials':
        return <NewTrialsTable refUsers={refUsers} isAffiliateLoading={isAffiliateLoading} />;
      case 'active_customers':
        return <ActiveCustomersTable refUsers={refUsers} isAffiliateLoading={isAffiliateLoading} />;
      case 'trial_cancelled':
        return <TrialCancelledTable refUsers={refUsers} isAffiliateLoading={isAffiliateLoading} />;
      case 'customer_cancelled':
        return <CustomerCancelledTable refUsers={refUsers} isAffiliateLoading={isAffiliateLoading} />;
      case 'all_customers':
        return <AllCustomersTable refUsers={refUsers} isAffiliateLoading={isAffiliateLoading} />;
      default:
        return <></>;
    }
  }

  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 }
  ];


  const years = [
    { label: "2024", value: 2024 },
    { label: "2023", value: 2023 },
  ];

  const calculateTimeLeft = (month) => {
    const difference = +new Date(month) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setTimeLeftOneMonth(calculateTimeLeft(formattedLastDay));
    }, 1000);

    return () => clearTimeout(timer1);
  }, []);

  useEffect(() => {
    const timer2 = setTimeout(() => {
      setTimeLeftThreeMonth(calculateTimeLeft(formattedLastDay));
    }, 1000);

    return () => clearTimeout(timer2);
  }, []);

  useEffect(() => {
    const timer3 = setTimeout(() => {
      setTimeLeftSixMonth(calculateTimeLeft(formattedLastDay));
    }, 1000);

    return () => clearTimeout(timer3);
  }, []);

  const GetDashboardData = () => {
    setLoader(true);
    dashboarddataApi(
      (response) => {
        setdDashboardData(response?.data?.dashboardData);
        setAffData(response?.data?.dashboardData)
        setLoader(false);
      },
      (error) => {
        setLoader(false);
        console.log(error?.response?.data);
      }
    );
  };


  const returnTimer = (timepara) => {
    // const numberCss = {
    //   fontFamily: "'Digital-7'",
    //   lineHeight: "33.57px",
    //   fontSize: "32px",
    //   fontWeight: "400",
    //   background: "#ffffff69",
    //   padding: "0px 2px"
    // }

    let days = "00";
    let hours = "00";
    let minutes = "00";
    let seconds = "00";

    if (Object.keys(timepara).length > 0) {
      days = timepara.days.toString().padStart(2, '0')
      hours = timepara.hours.toString().padStart(2, '0')
      minutes = timepara.minutes.toString().padStart(2, '0')
      seconds = timepara.seconds.toString().padStart(2, '0')
    }


    if (timepara.days > 0) {
      return (
        <>
          <div>
            <div>
              <span className="sep-timeSpan">{days[0]}</span>
              <span className="sep-timeSpan">{days[1]}</span>
            </div>
            <div className="sep-timeSpan-btm"
            >
              Days
            </div>
          </div>
          <div>
            <div>
              <span className="sep-timeSpan">{hours[0]}</span>
              <span className="sep-timeSpan">{hours[1]}</span>
            </div>
            <div className="sep-timeSpan-btm"
            >
              Hours
            </div>
          </div>
          <div>
            <div>
              <span className="sep-timeSpan">{minutes[0]}</span>
              <span className="sep-timeSpan">{minutes[1]}</span>
            </div>
            <div className="sep-timeSpan-btm"
            >
              Minutes
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <div>
              <span className="sep-timeSpan">{hours[0]}</span>
              <span className="sep-timeSpan">{hours[1]}</span>
            </div>
            <div className="sep-timeSpan-btm"
            >
              Hours
            </div>
          </div>
          <div>
            <div>
              <span className="sep-timeSpan">{minutes[0]}</span>
              <span className="sep-timeSpan">{minutes[1]}</span>
            </div>
            <div className="sep-timeSpan-btm"
            >
              Minutes
            </div>
          </div>
          <div>
            <div>
              <span className="sep-timeSpan">{seconds[0]}</span>
              <span className="sep-timeSpan">{seconds[1]}</span>
            </div>
            <div className="sep-timeSpan-btm"
            >
              Seconds
            </div>
          </div>
        </>
      );
    }
  };



  // const activeSale = 49;
  const activeSale = dashboardData?.personalActiveSale?.activeSale ?? 0;
  const widthPercentage = ((activeSale > 50 ? 50 : activeSale) / 50) * 100;

  // Define styles
  const styles = [
    { left: `calc(${widthPercentage}% - 20px)` },
    { left: `calc(${widthPercentage}% - 5px)` },
    { left: `calc(${widthPercentage}% + 10px)` },
    { left: `calc(${widthPercentage}% + 25px)` },
    { left: `calc(${widthPercentage}% + 40px)` },
    { left: `calc(${widthPercentage}% + 60px)` },
    { left: `calc(${widthPercentage}% + 80px)` },
    { left: `calc(${widthPercentage}% + 90px)` },
    { left: `calc(${widthPercentage}% + 100px)` },
    { left: `calc(${widthPercentage}% + 115px)` },
    { left: `calc(${widthPercentage}% + 125px)` },
    { left: `calc(${widthPercentage}% + 135px)` },
    { left: `calc(${widthPercentage}% + 145px)` },
    { left: `calc(${widthPercentage}% + 155px)` },
    { left: `calc(${widthPercentage}% + 165px)` },
    { left: `calc(${widthPercentage}% + 175px)` },
    { left: `calc(${widthPercentage}% + 185px)` },
    { left: `calc(${widthPercentage}% + 190px)` },
    { left: `calc(${widthPercentage}% + 190px)` },
    { left: `calc(${widthPercentage}% + 195px)` },
    { left: `calc(${widthPercentage}% + 205px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 215px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 215px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 205px)` },
    { left: `calc(${widthPercentage}% + 205px)` },
    { left: `calc(${widthPercentage}% + 195px)` },
    { left: `calc(${widthPercentage}% + 195px)` },
    { left: `calc(${widthPercentage}% + 185px)` },
    { left: `calc(${widthPercentage}% + 175px)` },
    { left: `calc(${widthPercentage}% + 165px)` },
    { left: `calc(${widthPercentage}% + 155px)` },
    { left: `calc(${widthPercentage}% + 145px)` },
    { left: `calc(${widthPercentage}% + 135px)` },
    { left: `calc(${widthPercentage}% + 125px)` },
    { left: `calc(${widthPercentage}% + 115px)` },
    { left: `calc(${widthPercentage}% + 105px)` },
    { left: `calc(${widthPercentage}% + 95px)` },
    { left: `calc(${widthPercentage}% + 85px)` },
    { left: `calc(${widthPercentage}% + 65px)` },
    { left: `calc(${widthPercentage}% + 45px)` },
    { left: `calc(${widthPercentage}% + 25px)` },
    { left: `calc(${widthPercentage}% + 05px)` },
    { left: `calc(${widthPercentage}% - 05px)` },
    { left: `calc(${widthPercentage}% - 20px)` },
  ];

  // Get the style based on the activeSale value
  const getStyle = () => {
    // Ensure activeSale is within the valid range
    const index = Math.min(activeSale, styles.length - 1);
    return styles[index] || styles[0]; // Return default style if index is out of range
  };

  const appliedStyle = getStyle();
  const totalUsers = dashboardData?.nextPayoutPer?.numberOfUsers || 0;
  // const activeSale1 = dashboardData?.personalActiveSale?.activeSale || 0;
  const progressValue = (activeSale / totalUsers) * 100;

  const commonButtonStyle = {
    width: "auto",
    height: "40px",
    borderRadius: "12px 12px 0px 0px",
    color: "rgba(21, 29, 72, 1)",
    backgroundColor: "rgb(255, 255, 255)",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    padding: "0 10px",

  };

  const activeStyle = {
    backgroundColor: "rgb(44, 115, 255)",
    color: "#fff",
  };

  const hoverStyle = {
    backgroundColor: "#e6e6e6",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const InvestmentData = () => {
    PayoutReportDataApi(
      (response) => {
        setPayoutReportData(response?.data?.data);
        // setObj(response?.data?.obj);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetDashboardData();
    InvestmentData();
  }, []);

  return (
    <AffiliateLayout>
      {
        loader
          ? <div style={{ position: 'absolute', height: "100vh", width: "100%" }}>
            <CircularProgress sx={{
              position: 'absolute',
              top: '50%',
              left: '40%',
              transform: 'translate(-50%, -50%)',
            }} /></div>
          : <div>
            <div
              className="sep-affiliate-dashboard-cards">
              <div className="bg-red sep-affiliate-dashboard-cardsInner">
                <div className="affiliate-dashboard-topbar-title">
                  {t("pages.title.lifetime-earning")}
                </div>
                <div
                  className="affiliate-dashboard-topbar-value">
                  {dashboardData?.cSymbol}{" "}
                  {(dashboardData?.lifeTImeEarning || 0).toFixed(2)}
                </div>
              </div>
              <div className="bg-blue sep-affiliate-dashboard-cardsInner">
                <div className="affiliate-dashboard-topbar-title">
                  {t("pages.title.last-month-earning")} ({dashboardData?.cSymbol})
                </div>
                <div
                  className="affiliate-dashboard-topbar-value">
                  {dashboardData?.cSymbol}{" "}
                  {(dashboardData?.lastMonthEarning || 0).toFixed(2)}
                </div>
              </div>
              <div className="bg-green sep-affiliate-dashboard-cardsInner">
                <div className="affiliate-dashboard-topbar-title">
                  {t("pages.title.total-payment")}
                </div>
                <div
                  className="affiliate-dashboard-topbar-value">
                  {dashboardData?.cSymbol}{" "}
                  {(dashboardData?.totalPayment || 0).toFixed(2)}
                </div>
              </div>
              <div className="bg-blue sep-affiliate-dashboard-cardsTimes"
                style={{
                  backgroundImage:
                    "url(/images/affiliate-dashboard-button-bg.svg)",
                }}
              >
                <div className="affiliate-dashboard-topbar-title">
                  {t("pages.title.end-of-the-month-in")}: {" "}
                </div>
                <div
                  className="affiliate-dashboard-topbar-value" >
                  <div className="sep-affiliate-time">
                    {returnTimer(timeLeftOneMonth)}
                  </div>
                </div>
              </div>
            </div>
            <div className="sep-earning-bouns-cards">
              <div className="sep-shadowCards" >
                <div className="sep-title-18"
                >
                  {t("pages.title.my-earning")}
                </div>
                <div>

                  <div

                    className="sep-earningItems"
                  >
                    <span>{t("pages.title.level")}{" "} 01</span>
                    <span>
                      {dashboardData?.cSymbol}{" "}
                      {(dashboardData?.totalEarning?.l1 || 0.0).toFixed(2)}
                    </span>
                  </div>
                  <div
                    className="sep-earningItems"
                  >
                    <span>{t("pages.title.level")}{" "} 02</span>
                    <span>
                      {dashboardData?.cSymbol}{" "}
                      {(dashboardData?.totalEarning?.l2 || 0.0).toFixed(2)}
                    </span>
                  </div>
                  <div
                    className="sep-earningItems"
                  >
                    <span>{t("pages.title.Others")}</span>
                    <span>
                      {dashboardData?.cSymbol}{" "}
                      {(dashboardData?.totalEarning?.others || 0).toFixed(2)}
                    </span>
                  </div>
                  <div className="sep-earningItemsGreen">
                    <span>{t("pages.title.total-earning")}</span>
                    <span style={{ color: "rgba(34, 154, 22, 1)" }}>
                      {dashboardData?.cSymbol}{" "}
                      {(
                        (dashboardData?.totalEarning?.l1 || 0.0) +
                        (dashboardData?.totalEarning?.l2 || 0.0) +
                        (dashboardData?.totalEarning?.others || 0.0)
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="sep-shadowCards">
                <div>
                  <h2 className="sep-title-18">
                    {t("pages.title.MySalesBonus")}
                  </h2>
                  <p className="sep-p">
                    {t("pages.title.personalactivesalesdetail")}
                  </p>
                </div>
                <div className="sep-bonusRows">
                  <div>
                    <div
                      className="sep-title-18"
                    >
                      {t("pages.title.my-current-payout")}
                    </div>
                    <div>
                      <div
                        className="sep-bonusBorderCards"
                      >
                        <div>{t("pages.title.Niveau1")}</div>
                        <div
                          className="sep-bonusBlueText"
                        >
                          {
                            totalUsers === 1
                              ? 20
                              : dashboardData?.currentPayoutPer?.l1}%
                        </div>
                      </div>
                      <div
                        className="sep-bonusBorderCards"
                      >
                        <div>{t("pages.title.Niveau2")}</div>
                        <div
                          className="sep-bonusBlueText"
                        >
                          {totalUsers === 1
                            ? 0
                            : dashboardData?.currentPayoutPer?.l2}%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="sep-title-18">
                      {t("pages.title.sales-needed-till-next-payout")}
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        sx={{
                          color: "rgba(44, 115, 255, 1)",
                          position: "absolute",
                          zIndex: 1,
                        }}
                        size={136}
                        variant="determinate"
                        value={progressValue > 100 ? 100 : progressValue}
                      />
                      <CircularProgress
                        sx={{ color: "rgba(44, 115, 255, 0.09)" }}
                        size={136}
                        variant="determinate"
                        value={100}
                      />
                      <label
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "32px",
                          fontWeight: "600",
                          lineHeight: "55px",
                          textAlign: "center",
                          position: "absolute",
                          color: "rgba(44, 115, 255, 1)",
                        }}
                      >
                        {activeSale}/{totalUsers}
                      </label>
                    </Box>
                  </div>
                  <div>
                    <div className="sep-title-18">
                      {t("pages.title.next-payout")}
                    </div>
                    <div>
                      <div
                        className="sep-bonusBorderCards"
                      >
                        <div>{t("pages.title.Niveau1")}</div>
                        <div
                          className="sep-bonusBlueText"
                        >
                          {
                            totalUsers === 1
                              ? 20
                              : dashboardData?.nextPayoutPer?.l1}%
                        </div>
                      </div>
                      <div
                        className="sep-bonusBorderCards"
                      >
                        <div>{t("pages.title.Niveau2")}</div>
                        <div
                          className="sep-bonusBlueText"
                        >
                          {
                            totalUsers === 1
                              ? 0
                              : dashboardData?.nextPayoutPer?.l2}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="sep-title-18">
                    {t("pages.title.my-personal-active-sale")}
                  </div>
                  <div>
                    <div
                      className="as-prog sep-as-prog" >
                      <div className="sep-as-progInner"
                        style={{
                          width: `${widthPercentage}%`,
                        }}
                      >
                        <span className='sep-active-sale-line' >
                          {activeSale}
                        </span>
                        <span>&nbsp;</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div 

              className="grid-box sep-grid-box"  >
              <div className="actual-payout sep-actual-payout">
                <h3 className="sep-title-24">
                  {t("pages.title.YourActualPayout%isBasedontheFollowing")}:
                </h3>
                <div
                  className="NVL-table1 sep-NVL-table1">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("pages.title.DirectActives")}</TableCell>
                        <TableCell>1</TableCell>
                        <TableCell>3</TableCell>
                        <TableCell>10</TableCell>
                        <TableCell>20</TableCell>
                        <TableCell>30</TableCell>
                        <TableCell>50</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      <TableRow>
                        <TableCell>{t("pages.title.Niveau1")}</TableCell>
                        <TableCell>20%</TableCell>
                        <TableCell>20%</TableCell>
                        <TableCell>22.5%</TableCell>
                        <TableCell>25%</TableCell>
                        <TableCell>30%</TableCell>
                        <TableCell>30%</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("pages.title.Niveau2")}</TableCell>
                        <TableCell>0%</TableCell>
                        <TableCell>20%</TableCell>
                        <TableCell>22.5%</TableCell>
                        <TableCell>25%</TableCell>
                        <TableCell>25%</TableCell>
                        <TableCell>30%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>

            <div className="data-table-area sep-data-table-area">
              <div className="sep-table-tabs">
                <div className="sep-table-tabsInner">

                  <button
                    onClick={() => setCurrentTab('new_trials')}
                    className="tabmenu-btn"
                    style={{
                      ...commonButtonStyle,
                      ...(currentTab === 'new_trials' ? activeStyle : {}),
                      ':hover': hoverStyle,
                    }}
                  >
                    {t("pages.title.new-trials")}
                  </button>
                  <button
                    onClick={() => setCurrentTab('active_customers')}
                    className="tabmenu-btn"
                    style={{
                      ...commonButtonStyle,
                      ...(currentTab === 'active_customers' ? activeStyle : {}),
                      ':hover': hoverStyle,
                    }}
                  >
                    {t("pages.title.active-customers")}
                  </button>

                  <button
                    onClick={() => setCurrentTab('trial_cancelled')}
                    className="tabmenu-btn"
                    style={{
                      ...commonButtonStyle,
                      ...(currentTab === 'trial_cancelled' ? activeStyle : {}),
                      ':hover': hoverStyle,
                    }}
                  >
                    {t("pages.title.cancelled-trials")}
                  </button>
                  <button
                    onClick={() => setCurrentTab('customer_cancelled')}
                    className="tabmenu-btn"
                    style={{
                      ...commonButtonStyle,
                      ...(currentTab === 'customer_cancelled' ? activeStyle : {}),
                      ':hover': hoverStyle,
                    }}
                  >
                    {t("pages.title.cancelled-customers")}
                  </button>
                  <button
                    onClick={() => setCurrentTab('all_customers')}
                    className="tabmenu-btn"
                    style={{
                      ...commonButtonStyle,
                      ...(currentTab === 'all_customers' ? activeStyle : {}),
                      ':hover': hoverStyle,
                    }}
                  >
                    {t("pages.title.all-customers")}
                  </button>
                </div>
                <div
                  className="title-area-right"
                  style={{
                    gap: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "4px 10px",
                    borderRadius: "4px",
                  }}

                >
                  <FormControl sx={{ width: "150px", backgroundColor: "#FFF", border: "2px solid #dae5ec", borderRadius: "9px" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedDate.month || ""} 
                      onChange={(e) => {
                        setSelectedDate((prev) => ({
                          ...prev,
                          month: e.target.value
                        }));
                      }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Month
                      </MenuItem>

                      {months.map((item) => (
                        <MenuItem
                          key={item.value}
                          style={{
                            background: selectedDate.month === item.value ? "#2C73FF" : "",
                            color: selectedDate.month === item.value ? "white" : ""
                          }}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>


                  <FormControl sx={{ width: "150px", backgroundColor: "#FFF", border: "2px solid #dae5ec", borderRadius: "9px" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedDate.year}
                      onChange={(e) => {
                        setSelectedDate((prev) => ({
                          ...prev,
                          year: e.target.value
                        }))
                      }}
                    >
                      {years.map((item) => (
                        <MenuItem
                          key={item.value}
                          style={{
                            background: selectedDate.year === item.value ? "#2C73FF" : "",
                            color: selectedDate.year === item.value && "white"
                          }}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      ))}

                    </Select>
                  </FormControl>

                </div>

              </div>
              {/* <div className="oct-affiliate-strip"> */}
                {/* <div className="oct-affiliate-stripInner" >
                  <h3 className="sep-title-18">
                    {TableTitle(currentTab)}
                  </h3>
                  {
                    currentTab === 'new_trials' 
                    ? <span className="sep-title-18" style={{fontWeight: '400', paddingLeft: '5px'}}>
                        {t("pages.title.last-30-days")}
                      </span>
                    : null
                  }
                </div> */}
                {/* <div className="oct-affiliate-stripBtn">
                  <Link className="sep-blue-Btns"
                  to={`/affiliate/all-customers?tab=${currentTab}`} >
                    <RxDashboard height={20} width={20} />{" "}
                    <span>{t("pages.title.view-all")}</span>
                  </Link>

                </div> */}

              {/* </div> */}
              {TableComponent(currentTab)}

            </div>

            <div className="data-table-area sep-data-table-area">
              <div className="sep-paymentable-inner">

                <h3 className="sep-title-18">
                  {t("pages.title.my-payments")}
                </h3>
                <PaymentTable payoutreportdata={payoutReportData} />
              </div>
            </div>
          </div>
      }
      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
        <TinyBarChart/>
        </Grid>
        <Grid item xs={12} md={6}>

        </Grid>
      </Grid> */}
    </AffiliateLayout>
  );
}
