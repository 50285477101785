import { Box } from "@mui/material";
import {
  DataGrid,
  GridPagination,
  GridToolbarQuickFilter,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import "./index.css";

export function Datatable(props) {
  return (
    <DataGrid
      slots={{ toolbar: QuickSearchToolbar, pagination: CustomPagination }}
      initialState={{
        pagination: { paginationModel: { pageSize: 25 } },
      }}
      {...props}
    />
  );
}

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      variant="outlined"
      shape="rounded"
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

export function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}



export function Datatable2(props) {
  return (
    <DataGrid
    
      slots={{ toolbar: QuickSearchToolbar, pagination: (paginationProps) => (
          <CustomPagination2 {...paginationProps} totalPages={props.totalPages} onPageTrigger={props.onPageTrigger} currentPage={props.currentPage}/>
        ) }}
      initialState={{
        pagination: { paginationModel: { pageSize:  props.pageSize} },
      }}
      pageSizeOptions={[25, 50, 100]}
      {...props}
    />
  );
}

function Pagination2({ page, onPageChange, className,totalPages,onPageTrigger,currentPage }) {
  const apiRef = useGridApiContext();
  const pageCount =  useGridSelector(apiRef, gridPageCountSelector);

  const handleClick = (event)=>{
    if(event.target.dataset.testid === 'NavigateBeforeIcon'){
      onPageTrigger(currentPage-1)
    }else if(event.target.dataset.testid === 'NavigateNextIcon'){
      onPageTrigger(currentPage+1)
    }else{
      onPageTrigger(event.target.innerText)
    }
    
  }
 

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={totalPages || pageCount}
      page={currentPage || page + 1}
      variant="outlined"
      shape="rounded"
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      onClick={(event) =>handleClick(event)}
    />
  );
}

export function CustomPagination2(props) {
  return <GridPagination ActionsComponent={(paginationProps) => <Pagination2 {...paginationProps} totalPages={props.totalPages} onPageTrigger={props.onPageTrigger} currentPage={props.currentPage} />} {...props} />;
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        px: 0.5,
        py: 1.5,
        "& .MuiInputBase-root": {
          "&:after": {
            border: "none",
          },
        },
      }}
    >
      <GridToolbarQuickFilter className="custom-search-class-nova" />
    </Box>
  );
}